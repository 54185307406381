<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
